import { Actions } from 'vuex-smart-module'

import UserState from './state'
import UserGetters from './getters'
import UserMutations from './mutations'

export default class UserActions extends Actions<
	UserState,
	UserGetters,
	UserMutations,
	UserActions
> {
	saveRF(data:{INN:string,KPP:string}){
		localStorage.setItem("INN", data.INN);
		localStorage.setItem("KPP", data.KPP);
		this.commit('setINN', data.INN)
		this.commit('setKPP', data.KPP)
	}
	saveOther(data:{otherName:string,otherEmail:string,otherCompany:string,otherCountry:string,otherPhone:string}){
		localStorage.setItem("otherName", data.otherName);
		localStorage.setItem("otherEmail", data.otherEmail);
		localStorage.setItem("otherCompany", data.otherCompany);
		localStorage.setItem("otherCountry", data.otherCountry);
		localStorage.setItem("otherPhone", data.otherPhone);
		this.commit('setOtherName', data.otherName)
		this.commit('setOtherEmail', data.otherEmail)
		this.commit('setOtherCompany', data.otherCompany)
		this.commit('setOtherCountry', data.otherCountry)
		this.commit('setOtherPhone', data.otherPhone)
	}
	changeAcceptOffer(acceptOffer:boolean){
		localStorage.setItem("acceptOffer", String(acceptOffer))
		this.commit('setAcceptOffer', acceptOffer)
	}
	async saveProducts(products: any[] ){
		this.commit('setProducts',products)
	}
}