import { Module, createStore } from 'vuex-smart-module'
import user from './user'


const root = new Module({
  modules: {
    user,
  },
})

const store = createStore(root)

export default store
