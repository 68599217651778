import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Skeleton from '@/views/Skeleton.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Skeleton',
		component: Skeleton,
		props: { mainView: 'UserHome' },
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
