import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import '@fontsource/roboto'
import '@fontsource/roboto/300.css'
import i18n from './i18n'
import Maska from 'maska'

createApp(App).use(i18n).use(router).use(store).use(Maska).mount('#app')
