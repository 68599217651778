import axios from "axios"
import store from "@/store"
const uri_server = process.env.VUE_APP_URI_SERVER

export default {
	post(url: string, data: any, headers?: any) {
		return axios.post(uri_server+"/api/"+url, data, headers)
	},

	login(email: string, password: string) {
		return new Promise((resolve) => {
			resolve(
				this.post('login', {
					email: email,
					password: password
				},
				)
			)
		})
	},
	
	refresh() {
		return new Promise((resolve) => {
			resolve(
				this.post('refresh', {}, {headers: this.getHeaders()})
				.then((response) => {
					store.dispatch('user/accessLogin',response.data)
				})
			)
		})
	},

	getHeaders() {
		return {
			'Content-Type': 'application/json',
			'Authorization': "Bearer "+localStorage.getItem('access_token')
		}
	},


	graphql(query: string, variables?: any) {
		return axios.post(uri_server+'/graphql', {
			query: query,
			variables: variables
		},
		{
			headers: this.getHeaders()
		})
	}


}