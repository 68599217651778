import { createI18n } from 'vue-i18n'

const ruMessages = require('@/locales/ru.json').default
const enMessages = require('@/locales/en.json').default

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  globalInjection: true,
  messages: { ru: ruMessages,en:enMessages}
})

export default i18n;