export default {
  "headers": {
    "RegistrationProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product registration"])},
    "CompanyID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company identification"])}
  },
  "submitInfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, your application has been submitted for review."])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list of ids:"])},
    "isntEndClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, contact the sales department"])}
  },
  "RegistrationProducts": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter IDS"])},
    "error": {
      "IDSNotRepeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS should not be repeated"])},
      "IDSIsNotCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS is not correct"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter data"])}
    }
  },
  "error": {
    "INNError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INN length must contain 10 or 12 digits"])},
    "KPPError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPP length must contain 9 digits"])},
    "EmailNotRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail is not registered"])},
    "CompanyNotRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company is not registered"])}
  },
  "CompanyFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company found"])},
  "isAccountExist": {
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a personal account?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "loginForm": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сonfirm"])}
  },
  "resident": {
    "RF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RF residence"])},
    "CIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIS"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "registrationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "RF": {
    "INN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INN"])},
    "KPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KPP"])},
    "placeholder": {
      "INN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter INN"])},
      "KPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter KPP"])}
    }
  },
  "other": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "placeholder": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Country"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter full name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter e-mail"])}
    }
  }
}