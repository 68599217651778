export default {
  "headers": {
    "RegistrationProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация продукта"])},
    "CompanyID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификация компании"])}
  },
  "submitInfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо, ваша заявка отправлена на рассмотрение."])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список ids:"])},
    "isntEndClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание, обратитесь в отдел продаж"])}
  },
  "RegistrationProducts": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["введите IDS"])},
    "error": {
      "IDSNotRepeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS не должны повторяться"])},
      "IDSIsNotCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS не верна"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите данные"])}
    }
  },
  "error": {
    "INNError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина ИНН должна содержать 10 или 12 цифр"])},
    "KPPError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина КПП должна содержать 9 цифр"])},
    "EmailNotRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта не зарегистрирована"])},
    "CompanyNotRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["компания не зарегистрирована"])}
  },
  "CompanyFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найденна компания"])},
  "isAccountExist": {
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есть личный кабинет?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])}
  },
  "loginForm": {
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
  },
  "resident": {
    "RF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резиденство РФ"])},
    "CIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["СНГ"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])}
  },
  "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "registrationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать"])},
  "RF": {
    "INN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
    "KPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КПП"])},
    "placeholder": {
      "INN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ИНН"])},
      "KPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите КПП"])}
    }
  },
  "other": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарий"])},
    "placeholder": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбирите страну"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите компанию"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ФИО"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите Почту"])}
    }
  }
}