import { Module } from 'vuex-smart-module'
import UserGetters from './getters'
import UserState from './state'
import UserMutations from './mutations'
import UserActions from './actions'

const user = new Module({
  state: UserState,
  getters: UserGetters,
  mutations: UserMutations,
  actions: UserActions
})

export default user