export default class UserState {
  isntEndClient = false
  INN:string=""
  KPP:string=""
  otherName:string=""
  otherEmail:string=""
  otherCompany:string=""
  otherCountry:string=""
  otherPhone:string=""
  products:any[]=JSON.parse(localStorage.getItem("products") || "[]");
  acceptOffer:Boolean=!!localStorage.getItem("acceptOffer") || false;
}