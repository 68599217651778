import { Mutations } from 'vuex-smart-module'
import UserState from './state'

export default class UserMutations extends Mutations<UserState> {

  setINN(INN: string) {
    this.state.INN = INN
  }
  setKPP(KPP: string) {
    this.state.KPP = KPP
  }  

  setOtherName(otherName: string) {
    this.state.otherName = otherName
  }
  setOtherEmail(otherEmail: string) {
    this.state.otherEmail = otherEmail
  }
  setOtherCompany(otherCompany: string) {
    this.state.otherCompany = otherCompany
  }
  setOtherCountry(otherCountry: string) {
    this.state.otherCountry = otherCountry
  }
  setProducts(products:any[]){
    this.state.products = products
  }
  setOtherPhone(otherPhone:string){
    this.state.otherPhone = otherPhone
  }
  setIsntEndClient(bool: boolean){
    this.state.isntEndClient = bool
  }
  setAcceptOffer(acceptOffer: boolean){
    this.state.acceptOffer = acceptOffer
  }
}